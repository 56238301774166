<template>
  <div style="background-color: #FAFAFA;padding-top: 15px;padding-bottom: 150px">
    <div style="background-color: white">
      <div class="center">
        <div style="height: 90px;padding-top: 27px" class="demand_type">
          <span v-for="item in demandType" :key="item.requirementTypeName"
                :class="{'span_select':demandIndex===item.requirementTypeName}"
                style="color: #999999;border-bottom: none"
                @click="changeDemandType(item.requirementTypeName,item.requirementType)">
            {{item.requirementTypeName}}
          </span>
        </div>
        <div style="height: 260px;padding: 55px 0 45px 0;display: flex" class="xmsb">

          <div v-for="item of toppingDemandList"
               style="border: 1px solid  rgba(220, 220, 220, .5);width: 300px;height: 160px;border-right: none">
            <router-link :to="{path: 'demandDetail',query:{'id':item.id}}"  style=";color: #666666" target="_blank">
            <span class="xmsb_circle"
                  style=" vertical-align:top;top:-4px;position:relative;padding-top:5px;padding-left:5px;color:white;min-height: 50px;width: 40px;background-color:#fc7c40;display: inline-block;margin-left: 10px;font-size: 14px">
                {{demandIndex}}
              </span>
              <div style="width: 240px;height: 100%;padding: 10px;display: inline-block">
                <div class="xqmc yi_hang_sheng" title="需求名称需求名称需求名称需求需求名称需求名称需求名称需求">
                  <span>{{item.title}}</span></div>
                <div class="xqnr liang_hang_sheng" style="margin-top: 10px;margin-bottom: 20px">
                  {{item.content}}
                </div>
                <div class="ysje" style="font-size: 14px"><span>预算金额：</span><span>协商确定</span></div>
                <div class="xqri" style="font-size: 14px"><span>需求日期：{{item.updatedTime}}</span></div>
              </div>
            </router-link>
          </div>

        </div>
      </div>
      <div class="qykjxq" @click="jumpToDemand" style="cursor: pointer">
        <span style="font-size: 22px;font-weight: bold">
          企业有科技需求？
        </span>
        <br>
        <span style="font-size: 14px">十大种类需求解决方案，高品质科技服务，全天候解决企业难题！</span>
        <br>
        <span style="font-size: 14px">说出你的难题，我们为您解决！</span>
      </div>
      <div class="center" style="padding-bottom: 110px">
        <div style="height: 100px;padding-top: 35px;padding-bottom: 30px">
          <div class="tixing_xuqiu_parent">
            <div class="tixing">
              最新需求
            </div>
          </div>
        </div>
        <List item-layout="vertical" :split="false" style="min-height: 300px" :loading="listLoading">
          <ListItem v-for="item of newestDemandList" :key="item.id" style="padding: 0">
            <router-link :to="{path: 'demandDetail',query:{'id':item.id}}"  style=";color: #666666" target="_blank">
              <div
                style="height: 200px;border: 1px solid rgba(220, 220, 220, .5);margin-bottom: 20px;padding: 20px 15px">
                <div style="margin-bottom: 30px"><span style="font-size: 18px;color: black;font-weight: bold;">{{item.title}}</span>
                </div>
                <div><span style="font-size: 14px;line-height: 25px" class="san_hang_sheng">
{{item.content}}
          </span>
                </div>
                <div style="font-size: 12px;margin-top: 10px">
                  <span>预算金额：协商确定</span> &nbsp;&nbsp;<span> 需求类型：{{item.requirementTypeName}}</span>&nbsp;&nbsp;
                  <span>需求日期：{{item.updatedTime}}</span>
                </div>
              </div>
            </router-link>
          </ListItem>
        </List>
        <Page @on-change="onPageChange" :current="pageNum" :page-size="10" :total="totalNum" show-elevator
              style="margin-top: 20px;float: right"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {getDemandListByType, getDemandTypeList} from "../../plugins/api/demandApi";
  import {getLocalToken} from "@/plugins/tools/storage";
  import {mapGetters} from "vuex";

  export default {
    name: "DemandHall",
    data() {
      return {
        demandIndex: '',
        demandType: [],
        toppingDemandList: [],//置顶需求列表
        newestDemandList: [],//最新需求列表
        listLoading: true,//是否在加载中
        pageNum: 1,//当前页
        totalNum: 0,//总个数
      }
    },
    mounted() {
      this.getDemandType();
    },
    computed: {
      ...mapGetters({
        token: 'userStore/token',
        userName: 'userStore/userName',
        userId: 'userStore/userId',
        enterpriseState: 'userStore/enterpriseState'
      })
    },
    methods: {
      jumpToDemand(){
          if(this.token && this.userId && getLocalToken()){
            this.$router.push({
              path:'/enterprise/enterpriseDemand'
            })
          }else{
            this.$router.push({
              path:'/login'
            })
          }
      },
      changeDemandType(index, name) {
        this.demandIndex = index;
        this.getDemandListById(name);
      },
      //获取需求分类
      async getDemandType() {
        const json = await getDemandTypeList();
        if (json && json.code === 0 && json.result && json.result.length > 0) {
          this.demandType = json.result
          this.demandIndex = json.result[0].requirementTypeName
          const params = {
            pageSize: 4,
            pageNum: 1,
            requirementType: json.result[0].requirementType
          }
          const res = await getDemandListByType(params);
          if (res && res.code === 0) {
            if (res.result && res.result.list && res.result.list.length > 0) {
              this.toppingDemandList = res.result.list
              this.getNewestDemandList();
            }
          }
        }
      },
      //获取需求类型需求列表
      async getDemandListById(type) {
        const params = {
          pageSize: 4,
          pageNum: 1,
          requirementType: type
        }
        const json = await getDemandListByType(params);
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.toppingDemandList = json.result.list
            // this.totalNum = json.result.total;
          }
        }
      },
      //获取最新需求
      async getNewestDemandList() {
        this.listLoading = true;
        const params = {
          pageSize: 10,
          pageNum: this.pageNum,
          requirementType: ""
        }
        const json = await getDemandListByType(params).catch(() => this.listLoading = false);
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.newestDemandList = json.result.list
            this.totalNum = json.result.total;
          }
        }
        this.listLoading = false;
      },
      //分页器变化监听
      onPageChange(pageNum) {
        this.pageNum = pageNum;
        this.getNewestDemandList();
      }
    }
  }
</script>

<style scoped lang="scss">

  /deep/ .ivu-page-item-active {
    background-color: #2d8cf0;
    /*background-color: #fc7c40;*/
    color: white !important;
    border: none;
  }

  /deep/ .ivu-page-item-active a {
    color: white !important;
  }

  .tixing_xuqiu_parent {
    width: 1200px;
    height: 40px;
    border-bottom: 1px solid #fc7c40;
    line-height: 40px;
  }

  .tixing {
    color: white;
    padding-left: 20px;
    width: 140px;
    height: 40px;
    background-color: #fc7c40;
    line-height: 40px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    font-size: 20px;
  }

  .tixing:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 40px solid white;
    border-left: 25px solid transparent;
    position: absolute;
    right: 0;
  }

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .san_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .xqmc {
    font-size: 14px;
    color: black;
  }

  .qykjxq {
    padding: 35px;
    color: white;
    height: 150px;
    text-align: center;
    background-image: url("~@/assets/image/index/top-first.png");
    background-position: center;
  }

  .yi_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .xmsb > div:last-child {
    border-right: 1px solid rgba(220, 220, 220, .5) !important;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }

  .span_select {
    border-bottom: 2px solid #fc7c40 !important;
    color: #fc7c40!important;
  }

  .demand_type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .demand_type span {
    cursor: pointer;
    display: inline-block;
    padding: 20px 0;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid rgba(220, 220, 220, .5);
  }

  .xmsb_circle:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    top: 0;
    left: 39px;
    background-color: #fc7c40;
    border-radius: 0 4px 0 0;
  }
</style>
